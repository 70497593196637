export interface Message {
  fromName: string;
  subject: string;
  date: string;
  id: number;
}

const messages: Message[] = [
  {
    fromName: 'Garry',
    subject: 'News update: something happened',
    date: '9:32 AM',
    id: 0
  },
  {
    fromName: 'Garry',
    subject: 'News update: something happened',
    date: '6:12 AM',
    id: 1
  },
  {
    fromName: 'Garry',
    subject: 'News update: something happened',
    date: '4:55 AM',
    id: 2

  },
  {
    fromName: 'Garry',
    subject: 'News update: something happened',
    date: 'Yesterday',
    id: 3
  }
];

export const getMessages = () => messages;

export const getMessage = (id: number) => messages.find(m => m.id === id);
